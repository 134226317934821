.Errorstyle {
    font-size: 13px;
    color: red;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  .detailsbutton {
    width: 200px;
  }

  .detailsbuttonpopup {
    width: 200px;
    margin-right: 50px;
  }

  .banbutton {
    right: 0;
    position: absolute;
  }

  .rolebutton {
    margin-left: 30px;
  }

  .acceptbutton {
    margin-left: 50px;
    width: 200px;
    background: green;
    color: white;
  }

  .acceptbuttonpopup {
    margin-right: 50px;
    width: 200px;
    background: green;
    color: white;
  }

  .my-popup-content {
    margin: auto;
    top: 0 !important;
    bottom: 0;
    left: 0 !important;
    right: 0;
    background: rgb(255, 255, 255);
    width: fit-content;
    height: fit-content;
    padding: 20px;
    inset: 0;
    border-radius: 10px;
    text-align: justify;
  }

  .popup-arrow {
    display: none;
  }
  
  [role="tooltip"].popup-content {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  [data-popup="tooltip"].popup-overlay {
    background: #000000c2;
  }